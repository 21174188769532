// extracted by mini-css-extract-plugin
export var course__btn = "students-module--course__btn--1ReqS";
export var courses__label = "students-module--courses__label--d3tPJ";
export var courses__list = "students-module--courses__list--y5OQc";
export var courses__payments = "students-module--courses__payments--kA0iI";
export var form__courses = "students-module--form__courses--iohV1";
export var form__submit = "students-module--form__submit--DjYyo";
export var grid__colss = "students-module--grid__colss--S+dd6";
export var grid__list = "students-module--grid__list--j6mPl";
export var item__details = "students-module--item__details--2J5mf";
export var item__name = "students-module--item__name--M2ig+";
export var item__subjects = "students-module--item__subjects--i-NUb";
export var loading = "students-module--loading--2r+3v";
export var modal__content = "students-module--modal__content--ajz0h";
export var modal__form = "students-module--modal__form--8nrsU";
export var modal__header = "students-module--modal__header--wZmZ7";
export var payments__list = "students-module--payments__list--y2Ybo";
export var students__edit = "students-module--students__edit--2S3J7";
export var students__title = "students-module--students__title--V+G27";
export var table = "students-module--table--sZwBa";