// extracted by mini-css-extract-plugin
export var actions__wrapper = "payment_card-module--actions__wrapper--BHQki";
export var content__info = "payment_card-module--content__info--BJC-M";
export var content__payments = "payment_card-module--content__payments--t9myN";
export var content__total_payment = "payment_card-module--content__total_payment--2VW2K";
export var cost = "payment_card-module--cost--gzb4T";
export var cost_currency = "payment_card-module--cost_currency--MkG-c";
export var cost_input = "payment_card-module--cost_input--tDkhq";
export var description = "payment_card-module--description--lAS9f";
export var expire = "payment_card-module--expire--jEqtm";
export var expire__inputs = "payment_card-module--expire__inputs--Xbmdc";
export var expire_check = "payment_card-module--expire_check--gS9jS";
export var info__main = "payment_card-module--info__main--u2GLk";
export var info__second = "payment_card-module--info__second--M2wUX";
export var payment = "payment_card-module--payment--+1bR5";
export var payment__actions = "payment_card-module--payment__actions--NQSCa";
export var payment__card = "payment_card-module--payment__card--YVRAh";
export var payments__form = "payment_card-module--payments__form--2Kr+5";
export var payments__title = "payment_card-module--payments__title--ayQRu";
export var price = "payment_card-module--price--htyy1";
export var students__payments = "payment_card-module--students__payments--tB1il";